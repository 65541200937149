import * as React from 'react';
import _ from 'lodash';
import { Button, Col, Row, Table } from 'reactstrap';
import { Div, ExternalLink, MainHeading, Markdown, SubPageLayout, SubSection, SubSections } from '../components';
import borrowData from '../data/borrow.json';
import { Link } from 'gatsby';
import classnames from 'classnames';

export const BorrowPageInternal = (borrow: typeof borrowData) => {
  return (
    <SubPageLayout
      title="Borrow"
      imageSrc={borrow.headerImageSrc}
    >
      <MainHeading
        label="Borrow"
        text="Whether you are looking to purchase or refinance your vehicle, refinance your home, make home repairs, or need cash for other needs, we have the loan for you.  With no pre-payment penalty, automatic payments, direct deposit, and remote check deposits, we have payment flexibility to fit your lifestyle."
      />

      <SubSections>
        {[
          <SubSection key="vehicle-loans" anchor="vehicle-loans" headerLabel="Vehicle Loans">
            <Row className="mb-3">
              <Col className="text-center child-spacing-x-5">
                <i className="fas fa-car section-icon" />
              </Col>
            </Row>

            <Row className="center-headings">
              <Col md={6}>
                <Markdown source={borrow.carsTrucksMotorcycles} />
              </Col>

              <Col md={6}>
                <Markdown source={borrow.recreationalVehicles} />
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="text-center">
                  <Button tag={ExternalLink} href="https://bsdcapi.onlinecu.com/racine/LoanApplications/racineDLA.html?docid=1" color="primary">Apply</Button>
                </div>
              </Col>

              <Col>
                <div className="text-center">
                  <Button tag={Link} to="/borrow/#loan-rates" color="primary">Rates</Button>
                </div>
              </Col>
            </Row>

            <Row className="d-none d-md-block my-3">
              <Col>
                <Markdown source={borrow.loansFooter} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="home-loans" anchor="home-loans" headerLabel="Home Loans">
            <Row className="mb-3">
              <Col className="text-center child-spacing-x-5">
                <i className="fa fa-home section-icon" />
              </Col>
            </Row>
            <Row className="center-headings">
              <Col className="d-flex flex-column" md={6}>
                <Div fill>
                  <Markdown source={borrow.fixedRate} />
                </Div>
              </Col>

              <Col className="d-flex flex-column" md={6}>
                <Div fill>
                  <Markdown source={borrow.adjustableRate} />
                </Div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  <Button tag={ExternalLink} href="https://bsdcapi.onlinecu.com/racine/LoanApplications/racineDLA.html?docid=1" color="primary">Apply</Button>
                </div>
              </Col>

              <Col>
                <div className="text-center">
                  <Button tag={Link} to="/borrow/#loan-rates" color="primary">Rates</Button>
                </div>
              </Col>
            </Row>

            <Row className="d-none d-md-block my-3">
              <Col>
                <Markdown source={borrow.loansFooter} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="home-equity-loans" anchor="home-equity-loans" headerLabel="Home Equity Loans">
            <Row className="mb-3">
              <Col className="text-center child-spacing-x-5">
                <i className="fa fa-hammer section-icon" />
              </Col>
            </Row>

            <Row className="center-headings">
              <Col md={6}>
                <Markdown source={borrow.homeEquityLoans} />
              </Col>
              <Col md={6}>
                <Markdown source={borrow.lineOfCredit} />
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="text-center">
                  <Button tag={ExternalLink} href="https://bsdcapi.onlinecu.com/racine/LoanApplications/racineDLA.html?docid=1" color="primary">Apply</Button>
                </div>
              </Col>

              <Col>
                <div className="text-center">
                  <Button tag={Link} to="/borrow/#loan-rates" color="primary">Rates</Button>
                </div>
              </Col>
            </Row>

            <Row className="d-none d-md-block my-3">
              <Col>
                <Markdown source={borrow.loansFooter} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="credit-cards" anchor="credit-cards" headerLabel="Credit Cards">
            <Row className="mb-3">
              <Col className="text-center child-spacing-x-5">
                <i className="fab fa-cc-visa section-icon" />
              </Col>
            </Row>

            <Row className="center-headings">
              <Col md={6}>
                <Markdown source={borrow.platinumVisa} />
              </Col>
              <Col md={6}>
                <Markdown source={borrow.classicVisa} />
              </Col>
            </Row>

            <Row>
              <Col className="child-spacing-y-1">
                <div className="text-center">
                  <a className="btn btn-primary" href="/pdfs/credit-card-application-solicitation-disclosure.pdf" target="_blank" rel="noreferrer noopener">Application and Solicitation Disclosure</a>
                </div>
                <div className="text-center">
                  <a className="btn btn-primary" href="/pdfs/credit-card-agreement-disclosure.pdf" target="_blank" rel="noreferrer noopener">Credit Card Agreement and Disclosure</a>
                </div>
                <div className="text-center">
                  <ExternalLink className="btn btn-primary" href="https://www.ezcardinfo.com/" target="_blank" rel="noreferrer noopener">Access Your Credit Card Info</ExternalLink>
                </div>
                <div className="text-center">
                  <a className="btn btn-primary" href="/pdfs/credit-card-application.pdf" target="_blank" rel="noreferrer noopener">Credit Card Application</a>
                </div>
              </Col>
            </Row>

            <Row className="d-none d-md-block my-3">
              <Col>
                <Markdown source={borrow.loansFooter} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="personal-loans" anchor="personal-loans" headerLabel="Personal Loans">
            <Col className="text-center child-spacing-x-5">
              <i className="fa fa-dollar-sign section-icon" />
            </Col>

            <Row className="center-headings">
              <Col md={6}>
                <Markdown source={borrow.unsecuredLoans} />
              </Col>
              <Col md={6}>
                <Markdown source={borrow.emergencyLoan} />
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="text-center">
                  <Button tag={ExternalLink} href="https://bsdcapi.onlinecu.com/racine/LoanApplications/racineDLA.html?docid=1" color="primary">Apply</Button>
                </div>
              </Col>

              <Col>
                <div className="text-center">
                  <Button tag={Link} to="/borrow/#loan-rates" color="primary">Rates</Button>
                </div>
              </Col>
            </Row>

            <Row className="d-none d-md-block my-3">
              <Col>
                <Markdown source={borrow.loansFooter} />
              </Col>
            </Row>

            <Row className="d-block d-md-none my-3">
              <Col>
                <Markdown source={borrow.loansFooter} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="loan-rates" anchor="loan-rates" headerLabel="Loan Rates">
            <h5 className="text-center">{borrow.vehicleLoans.label}</h5>
            <Table borderless size="sm">
              <thead>
                <tr>
                  <th style={{ width: '42%' }}>Model Year</th>
                  <th style={{ width: '42%' }}>Term (months)</th>
                  <th className="pb-0">APR*</th>
                </tr>
                <tr>
                  <th />
                  <th />
                  <th className="pt-0" style={{ lineHeight: 0 }}><small>as low as</small></th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(borrow.vehicleLoans.rates, (rate, i) => (
                    <tr key={i}>
                      <td>{rate.modelYear}</td>
                      <td>{rate.term}</td>
                      <td>{rate.apr}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>

            <Markdown source={borrow.vehicleLoans.aside} />

            <h5 className="text-center">{borrow.homeLoans.label}</h5>
            <Table borderless size="sm">
              <thead>
                <tr>
                  <th style={{ width: '42%' }}>Fixed Rate</th>
                  <th style={{ width: '42%' }}>Term</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(borrow.homeLoans.rates, (rate, i) => (
                    <tr key={i}>
                      <td className={classnames({ 'font-weight-bold': rate.boldHeader })}>{rate.fixedRate}</td>
                      <td>{rate.term}</td>
                      <td>{rate.apr}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>

            <Markdown source={borrow.homeLoans.footer} />

            <h5 className="text-center">{borrow.recreationalVehicleRates.label}</h5>
            <Table borderless size="sm">
              <thead>
                <tr>
                  <th style={{ width: '42%' }}>Model Year</th>
                  <th style={{ width: '42%' }}>Term (months)</th>
                  <th>APR*</th>
                </tr>
                <tr>
                  <th />
                  <th />
                  <th className="pt-0" style={{ lineHeight: 0 }}><small>as low as</small></th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(borrow.recreationalVehicleRates.rates, (rate, i) => (
                    <tr key={i}>
                      <td style={{ width: '33%' }}>{rate.modelYear}</td>
                      <td style={{ width: '33%' }}>{rate.term}</td>
                      <td style={{ width: '33%' }}>{rate.apr}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>

            <h5 className="text-center">{borrow.visaCreditCard.label}</h5>
            <Table borderless size="sm">
              <thead>
                <tr>
                  <th />
                  <th>APR*</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(borrow.visaCreditCard.rates, (rate, i) => (
                    <tr key={i}>
                      <td style={{ width: '84%' }}>{rate.name}</td>
                      <td>{rate.apr}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>

            <Markdown source={borrow.aprAside} />

            <h5 className="text-center">{borrow.personalLoans.label}</h5>
            <Table borderless size="sm">
              <thead>
                <tr>
                  <th style={{ width: '42%' }}>Amount</th>
                  <th style={{ width: '42%' }}>Term (months)</th>
                  <th className="pb-0">APR*</th>
                </tr>
                <tr>
                  <th />
                  <th />
                  <th className="pt-0" style={{ lineHeight: 0 }}><small>as low as</small></th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(borrow.personalLoans.rates, (rate, i) => (
                    <tr key={i}>
                      <td>{rate.amount}</td>
                      <td>{rate.term}</td>
                      <td>{rate.apr}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>

            <Markdown source={borrow.personalLoans.aside} />
          </SubSection>,

          <SubSection key="loan-application" anchor="loan-application" headerLabel="Loan Application">
            <Markdown source={borrow.loanApplication} />
            <div className="text-center">
              <ExternalLink className="btn btn-primary" href={borrow.startApplicationLink}>
                Start Application
              </ExternalLink>
            </div>
          </SubSection>,
        ]}
      </SubSections>
    </SubPageLayout>
  );
};

export default () => <BorrowPageInternal {...borrowData} />;
